<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    :close-on-click-modal="false"
    top="7vh"
    width="70%"
    append-to-body
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="paperTitle"></span>
    </div>
    <!-- this.formData.questionList：{{this.formData.questionList}} -->
    <!-- editPaperData：{{editPaperData}} -->
    <!-- formData：{{formData}} -->
    <!-- formData.questionLists：{{formData.questionList}}
    <hr>
    oldList：{{oldList}}
    <hr>
    newList：{{newList}} -->
    <!-- userTime：{{userTime}} -->
    <el-form ref="formData" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="试卷名称：" prop="title">
        <el-input v-model="formData.title" placeholder="请输入试卷名称"></el-input>
      </el-form-item>
      <el-form-item label="答题时长：">
        <el-input-number v-model="formData.limitTime" :min="0" label="请输入答题时间"></el-input-number>
        <span style="display: inline-block;color: #ccc;margin-left: 25px;">分钟（0为不限时）</span>
      </el-form-item>
      <el-form-item label="展示分数：">
        <el-radio-group v-model="formData.isShowScore" size="mini">
          <el-radio-button label="是"></el-radio-button>
          <el-radio-button label="否"></el-radio-button>
        </el-radio-group>
        <span style="display: inline-block;color: #ccc;margin-left: 25px;">（学生端是否展示分数）</span>
      </el-form-item>
      <el-form-item label="发布结果：" size="mini">
        <el-radio-group v-model="formData.autoResult">
          <el-radio-button label="是"></el-radio-button>
          <el-radio-button label="否"></el-radio-button>
        </el-radio-group>
        <span style="display: inline-block;color: #ccc;margin-left: 25px;">（交卷后立刻发布结果）</span>
      </el-form-item>
      <el-form-item label="再次答题：">
        <el-radio-group v-model="formData.isRepeatable" size="mini">
          <el-radio-button label="是"></el-radio-button>
          <el-radio-button label="否"></el-radio-button>
        </el-radio-group>
        <span style="display: inline-block;color: #ccc;margin-left: 25px;">（试卷可被多次提交）</span>
      </el-form-item>
      <!-- <el-form-item label="是否批阅：">
        <el-radio-group v-model="formData.isReview" size="mini">
          <el-radio-button label="是"></el-radio-button>
          <el-radio-button label="否"></el-radio-button>
        </el-radio-group>
      </el-form-item>-->
      <el-form-item label="试题列表：" style="margin-bottom: 10px;">
        <!-- formData.questionList：{{formData.questionList}} -->
        <el-button type="primary" @click.native="seleTaskPaper()" class="mb-10" size="small" :disabled="userTime !== 0">选择试题</el-button>
        <el-table :data="formData.questionList" border fit highlight-current-row ref="dragTable" row-key="questionId">
          <el-table-column label="排序" align="center" width="60">
            <span class="el-icon-rank"></span>
          </el-table-column>
          <el-table-column label="ID" align="center" width="100">
            <template slot-scope="scope">
              <span>{{scope.row.questionId | uuidFormat}}</span>
            </template>
          </el-table-column>
          <el-table-column label="试题" align="center">
            <template slot-scope="scope">
              <span @click="editSubject(scope.row)" class="title-link" v-text="filterHTMLTag(scope.row.question.stem)"></span>
            </template>
          </el-table-column>
          <el-table-column label="标签" align="center">
            <template slot-scope="scope">
              <!-- <span>{{scope.row.question.tagList}}</span> -->
              <template v-if="!scope.row.question.tagList || scope.row.question.tagList.length === 0">
                <span>无</span>
              </template>
              <template v-else>
                <span v-for="item of scope.row.question.tagList" :key="item.id">
                  <template v-if="Boolean(item.groupInfo)">
                    <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+ item.groupInfo.color"></span>
                  </template>
                  <template v-else>
                    <span v-text="item.title" class="tag" :style="'backgroundColor'+':'+ '#ccc'"></span>
                  </template>
                </span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="分值" align="center" width="180">
            <template slot-scope="scope">
              <el-input
                v-model="scope.row.score"
                placeholder="请输入题目分值"
                @blur="onInputBlur(scope.row.score, scope.$index)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100" v-if="userTime === 0">
            <template slot-scope="scope">
              <span
                class="el-icon-remove"
                style="font-size: 25px;cursor: pointer;line-height: 40px;"
                @click="delQuestion(scope.row, scope.$index)"
              ></span>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>

    <el-row slot="footer">
      <el-button type="primary" @click="savePaper()" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>

    <!-- 试题管理 -->
    <manage-topic
      :show="manageTopicDialog"
      @close="manageTopicDialog=false;editAble=false"
      @saveTask="saveTask"
      @editTask="editTask"
      :seleTaskData="seleTaskData"
      :editPaperData="editPaperData"
      :editAble="editAble"
    ></manage-topic>

    <!-- 添加单选题 -->
    <single-choicee
      :show="singleDialog"
      :ExamDialog="ExamDialog"
      @close="singleDialog=false;editTopicData=null"
      @getExamInfo="getExamInfo"
      :editTopicData="editTopicData"
    ></single-choicee>

    <!-- 添加多选题 -->
    <multiple-choicee
      :show="multipleDialog"
      :ExamDialog="ExamDialog"
      @close="multipleDialog=false;editTopicData=null"
      @getExamInfo="getExamInfo"
      :editTopicData="editTopicData"
    ></multiple-choicee>

    <!-- 添加简答题 -->
    <essay-question
      :show="questionDialog"
      :ExamDialog="ExamDialog"
      @close="questionDialog=false;editTopicData=null"
      @getExamInfo="getExamInfo"
      :editTopicData="editTopicData"
    ></essay-question>

    <!-- 添加填空题 -->
    <filling-question
      :show="fillingDialog"
      :ExamDialog="ExamDialog"
      @close="fillingDialog=false;editTopicData=null"
      @getExamInfo="getExamInfo"
      :editTopicData="editTopicData"
    ></filling-question>
  </el-dialog>
</template>

<script>
import manageTopic from './manageTopic'
import { questionGetInfo } from '@/api/research/testquestion'
import { getExamInfo, getUsedTimes } from '@/api/research/exam'

import Sortable from 'sortablejs'
import singleChoicee from '@/components/research/testquestion-manage/singleChoice' // 单选
import multipleChoicee from '@/components/research/testquestion-manage/multipleChoice' // 多选
import essayQuestion from '@/components/research/testquestion-manage/essayQuestion' // 简答
import fillingQuestion from '@/components/research/testquestion-manage/fillingQuestion' // 填空

export default {
  components: {
    manageTopic,
    singleChoicee,
    multipleChoicee,
    essayQuestion,
    fillingQuestion
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    isCopy: { // 是否复制试卷
      default: false,
      type: Boolean
    },
    isPublish: { // 是否发布试卷 - （目前创建试卷默认发布状态 - 参数已废弃）
      default: false,
      type: Boolean
    },
    editPaperData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },

  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },

  data () {
    return {
      ExamDialog: true,
      paperTitle: '',
      userTime: 0,
      formData: {
        title: '',
        limitTime: '',
        // isReview: "否", // 是否批阅
        autoResult: '是', // 是否提交后就可查看结果
        isShowScore: '否', // 是否计分
        isRepeatable: '是', // 是否可以重复交卷
        questionList: [
          {
            score: '',
            questionId: '',
            question: {
              id: '',
              stem: ''
            }
          }
        ]
      },
      editTopicData: null,
      singleDialog: false,
      multipleDialog: false,
      questionDialog: false,
      fillingDialog: false,
      question: {}, // 试题详细信息
      rules: {
        title: [{ required: true, message: '请填写试卷名称', trigger: 'blur' }],
      },
      manageTopicDialog: false,
      taskIndex: 0,
      seleTaskData: [], // 选中试题详细信息
      validatorStatusList: [],
      validatorScore: [],
      editAble: false,
      oldList: [],
      newList: [],
      SortText: ''
    }
  },
  methods: {
    openDialog () {
      this.seleTaskData = []
      this.userTime = 0
      this.formData = {
        title: '',
        limitTime: '',
        // isReview: "否", // 是否批阅
        autoResult: '是', // 是否提交后就可查看结果
        isShowScore: '否', // 是否计分
        isRepeatable: '是', // 是否可以重复交卷
        questionList: [
          {
            score: '',
            questionId: '',
            question: {
              id: '',
              stem: ''
            }
          }
        ]
      }
      if (this.editPaperData !== null) {
        this.paperTitle = '编辑试卷'
        this.getExamInfo()
        this.getUsedTimes(this.editPaperData.id)
      } else {
        this.paperTitle = '新增试卷'
      }
    },

    filterHTMLTag (msg) {
      var msg = msg.replace(/<\/?[^>]*>/g, '') //去除HTML Tag
      msg = msg.replace(/[|]*\n/, '') //去除行尾空格
      msg = msg.replace(/&nbsp;/ig, '') //去掉nbsp
      return msg.substring(0, 20)
    },

    editSubject (row) {
      // console.log('editSubject', row)
      // if (!row.question) {
      // } else {
      // }
      this.editTopicData = row.question
      if (row.question.type === '单选') {
        this.singleDialog = true
      } else if (row.question.type === '多选') {
        this.multipleDialog = true
      } else if (row.question.type === '简答') {
        this.questionDialog = true
      } else {
        this.fillingDialog = true
      }
    },

    // 分值输入框
    onInputBlur (value, index) {
      var reg = /^[0-9]+.?[0-9]*$/ // 判断字符串是否为自然数 ，判断正整数用/^[1-9]+[0-9]*]*$/
      if (!reg.test(value)) {
        window.$msg('请输入有效的自然数', 2)
        this.formData.questionList[index].score = 0
        return false
      } else {
        return true
      }
    },

    // 获取试卷详细信息
    getExamInfo () {
      getExamInfo({
        id: this.editPaperData.id
      }).then(res => {
        if (res.state === 'success') {
          this.formData = Object.assign({}, res.body)

          this.oldList = this.formData.questionList.map(v => v.questionId)
          this.newList = this.oldList.slice()
          this.$nextTick(() => {
            this.setSort()
          })
        }
      })
    },

    // 获取试卷使用次数
    getUsedTimes (examId) {
      getUsedTimes({
        examId
      }).then(res => {
        if (res.state === 'success') {
          this.userTime = res.body
        }
      })
    },
    // 关闭弹窗
    close () {
      this.$emit('close')
    },

    // 选择试题
    seleTaskPaper (item, index) {
      if (!this.formData.questionList[0].question.stem) {
        this.seleTaskData = []
      } else {
        this.seleTaskData = []
        this.formData.questionList.forEach((element, index) => {
          this.seleTaskData.push({
            id: element.questionId,
            ...element
          })
        })
      }
      // console.log('this.seleTaskData', this.seleTaskData)
      // this.seleTaskData.push({
      //   id: item.questionId,
      //   stem: item.question.stem
      // })
      // this.taskIndex = index
      this.manageTopicDialog = true
    },

    // 保存试题
    async saveTask (data) {
      let questionList = Object.assign([], this.formData.questionList)
      this.formData.questionList = []
      data.forEach((item, index) => {
        if (!item.question) {
          this.formData.questionList.push(
            {
              score: '',
              questionId: item.id,
              question: {
                ...item
              }
            }
          )
        } else {
          this.formData.questionList.push(
            {
              score: '',
              questionId: item.id,
              question: {
                ...item.question
              }
            }
          )
        }
      })

      // console.log('questionList', this.formData.questionList)
      questionList.forEach((element, index) => {
        this.formData.questionList[index].score = element.score
      })

      this.oldList = this.formData.questionList.map(v => v.questionId)
      this.newList = this.oldList.slice()
      this.$nextTick(() => {
        this.setSort()
      })
      // if (this.editPaperData !== null) { // 编辑试卷操作
      //   let questionList = Object.assign([], this.formData.questionList)
      //   this.formData.questionList = []
      //   data.forEach((item, index) => {
      //     if (!item.question) {
      //       this.formData.questionList.push(
      //         {
      //           score: '',
      //           questionId: item.id,
      //           question: {
      //             ...item
      //           }
      //         }
      //       )
      //     } else {
      //       this.formData.questionList.push(
      //         {
      //           score: '',
      //           questionId: item.id,
      //           question: {
      //             ...item.question
      //           }
      //         }
      //       )
      //     }
      //   })

      //   // console.log('questionList', this.formData.questionList)
      //   questionList.forEach((element, index) => {
      //     this.formData.questionList[index].score = element.score
      //   })
      // } else {
      //   this.formData.questionList = []
      //   data.forEach((item, index) => {
      //     if (!item.question) {
      //       this.formData.questionList.push(
      //         {
      //           score: '',
      //           questionId: item.id,
      //           question: {
      //             ...item
      //           }
      //         }
      //       )
      //     } else {
      //       this.formData.questionList.push(
      //         {
      //           score: '',
      //           questionId: item.id,
      //           question: {
      //             ...item.question
      //           }
      //         }
      //       )
      //     }
      //   })
      //   this.oldList = this.formData.questionList.map(v => v.questionId)
      //   this.newList = this.oldList.slice()
      //   this.$nextTick(() => {
      //     this.setSort()
      //   })
      // }
      // console.log('this.formData.questionList', this.formData.questionList)
      // let topicId = data[0].id
      // const res = await questionGetInfo({
      //   id: topicId
      // })
      // this.question = { ...res.body }
      // this.formData.questionList[this.taskIndex].question = Object.assign({}, res.body)
      // this.formData.questionList[this.taskIndex].questionId = topicId
    },

    async editTask (data) {
      let topicId = data[0].id
      const res = await questionGetInfo({
        id: topicId
      })
      this.question = { ...res.body }
      this.formData.questionList[this.taskIndex].question = Object.assign({}, res.body)
      this.formData.questionList[this.taskIndex].questionId = topicId
    },

    // 添加试题
    addQuestion (item, index) {
      if (this.formData.questionList.length >= 10) {
        window.$msg('试题最多10个', 1)
      } else {
        this.formData.questionList.push({
          score: '',
          questionId: '',
          question: {
            id: '',
            title: ''
          }
        })
      }
    },

    // 删除试题
    delQuestion (item, index) {
      if (this.formData.questionList.length === 1) {
        window.$msg('试卷至少保留一道试题', 2)
      } else {
        this.formData.questionList.splice(index, 1)
      }
    },

    // 试题排序
    setSort () {
      const el = this.$refs.dragTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      this.sortable = Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        setData: (dataTransfer) => {
          dataTransfer.setData('Text', '')
        },
        onEnd: evt => {
          const targetRow = this.formData.questionList.splice(evt.oldIndex, 1)[0]
          this.formData.questionList.splice(evt.newIndex, 0, targetRow)
          const tempIndex = this.newList.splice(evt.oldIndex, 1)[0]
          this.newList.splice(evt.newIndex, 0, tempIndex)
        }
      })
    },

    // 添加/保存试卷
    savePaper () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          this.validatorStatusList = []
          this.validatorScore = []
          this.formData.questionList.forEach((item, index) => {
            if (!item.question.stem && !item.questionId) {
              this.validatorStatusList.push('false')
            } else {
              this.validatorStatusList.push('true')
            }
            if (!item.score) {
              this.validatorScore.push('false')
            } else {
              this.validatorScore.push('true')
            }
          })
          let state = this.validatorStatusList.includes('false')
          let scoreState = this.validatorScore.includes('false')
          if (state) {
            window.$msg('试题不能为空', 2)
          } else if (scoreState) {
            window.$msg('分数不能为空', 2)
          } else {
            if (this.editPaperData !== null && this.isCopy) {
              // 复制模板试卷
              this.$emit('addData', this.formData, this.isPublish)
            } else if (this.editPaperData !== null && !this.isCopy) {
              // 编辑试卷
              this.$emit('editData', this.formData)
            } else {
              this.$emit('addData', this.formData, this.isPublish)
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
/deep/ .el-dialog__wrapper {
  z-index: 5000 !important;
}

.tag {
  display: inline-block;
  color: #fff;
  border-radius: 17px 20px 0px 17px;
  padding: 3px 15px;
  margin-right: 10px;
  margin-bottom: 8px;
}
</style>
